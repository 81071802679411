<template>
  <div>
    <hedaer @setSelect="setItem"></hedaer>
    <div class="journalism item">
      <div class="wow fadeInUp" data-wow-delay="0.3s">
        <ul class="home-tab" style="padding: 0 1.8519rem">
          <li :class="{ selectBab: selectBab == 1 }" @click="selectBab = 1">
            视频报道
          </li>
          <li :class="{ selectBab: selectBab == 2 }" @click="selectBab = 2">
            企业动态
          </li>
        </ul>
        <div class="home-line"></div>
      </div>
      <div v-show="selectBab == 1">
        <div class="Profile-title wow fadeInUp" data-wow-delay="0.4s">
          视频报道
        </div>
        <div class="Profile-en wow fadeInUp" data-wow-delay="0.5s">
          TV REPORTS
        </div>
        <div
          class="VideoReport wow fadeInUp"
          v-for="(item, index) in vidoList"
          :style="{
            marginBottom: index == vidoList.length - 1 ? '0' : '.3704rem',
          }"
          :key="index"
          @click="showClick(index)"
        >
          <img
            src="../assets/journalism/bf.png"
            class="VideoReport-bf"
            alt=""
          />

          <img :src="item.img" alt="" class="VideoReport-img" />
          <div class="VideoReport-item">{{ item.text }}</div>
        </div>
        <div class="lookMore" @click="lookMore" v-if="isNoAdd">
          查看更多
          <div class="out-top"></div>
        </div>
      </div>
      <div v-show="selectBab == 2">
        <div class="Profile-title wow fadeInUp">企业动态</div>
        <div class="Profile-en wow fadeInUp">COMPANY NEWS</div>
        <div class="Dynamics">
          <div class="Dynamics-line wow fadeInUp"></div>
          <div
            class="Dynamics-item wow fadeInUp"
            v-for="(item, index) in DynamicsList"
            :key="index"
            @click="$router.push(`/info?id=${index}`)"
            :data-wow-delay="`0.${index}s`"
          >
            <div class="Dynamics-left">
              <div class="Dynamics-title">{{ item.text }}</div>
              <div class="Dynamics-date">{{ item.date }}</div>
            </div>
            <img :src="item.img" alt="" class="Dynamics-right" />
          </div>
          <div class="lookMore" @click="lookMoreT" v-if="isNoAddT">
            查看更多
            <div class="out-top"></div>
          </div>
        </div>
      </div>
      <journalismProp
        @setShow="show = false"
        :isSelect="isSelect"
        v-if="show"
      ></journalismProp>
    </div>

    <Footer @setItem="setItem"></Footer>
  </div>
</template>

<script>
import journalismProp from '@/components/journalismProp.vue'
export default {
  components: {
    journalismProp
  },
  name: 'journalism',
  data () {
    return {
      isSelect: 0,
      show: false,
      isNoAdd: true,
      isNoAddT: true,
      vidoList: [
        {
          img: `${this.$url}Journalism/Journalism1.jpg`,
          text: 'CCTV 《创新之路》'
        },
        {
          img: `${this.$url}Journalism/Journalism2.jpg`,
          text: 'CCTV13 《新闻直播间》 '
        },
        {
          img: `${this.$url}Journalism/Journalism3.jpg`,
          text: '人民网《人民会客厅·两会时刻》 '
        },
        {
          img: `${this.$url}Journalism/Journalism4.jpg`,
          text: 'CCTV1 《生活圈》 '
        },
        {
          img: `${this.$url}Journalism/Journalism5.jpg`,
          text: 'BTV 《品质生活》'
        },
        {
          img: `${this.$url}Journalism/Journalism6.jpg`,
          text: 'CCTV 《连线中国》 '
        }
      ],
      addVidoList: [
        {
          img: `${this.$url}Journalism/style7.jpg`,
          text: 'CCTV《焦点关注》'
        },
        {
          img: `${this.$url}Journalism/style8.jpg`,
          text: '钛媒体《华楠直播间》'
        },
        {
          img: `${this.$url}Journalism/style9.jpg`,
          text: '山西电视台《黄河新闻》'
        },
        {
          img: `${this.$url}Journalism/style10.jpg`,
          text: '广东电视台《创新广东》'
        },
        {
          img: `${this.$url}Journalism/style11.jpg`,
          text: '甘肃电视台《今日文化君》'
        },
        {
          img: `${this.$url}Journalism/style12.jpg`,
          text: '南昌电视台《新闻说报》'
        }
      ],
      DynamicsList: [
        {
          img: `${this.$url}JournalismInfo/15/6.jpg`,
          text: '碧莲盛植发技术再升级，无痛不剃发植发正式亮相',
          date: '2023-08-28',
          NewsType: 'old'
        },
        {
          img: `${this.$url}JournalismInfo/15/7.jpg`,
          text: '碧莲盛发布三大重磅升级，引领植发业高质量发展',
          date: '2023-08-28',
          NewsType: 'old'
        },
        {
          img: `${this.$url}Journalism/style1.jpg`,
          text: '估值再创新高，“头部”企业碧莲盛如何实现逆势增长？',
          date: '2022-06-15'
        },
        {
          img: `${this.$url}Journalism/style2.jpg`,
          text: '播种希望 筑梦未来 碧莲盛开展六一爱心助学公益行',
          date: '2022-06-01'
        },
        {
          img: `${this.$url}Journalism/style3.jpg`,
          text: '碧莲盛荣获胡润百富“最青睐的高端植发品牌”',
          date: '2022-01-20'
        },
        {
          img: `${this.$url}Journalism/style4.jpg`,
          text: '不忘初心，勇于担当，碧莲盛荣获企业社会责任行业典范奖',
          date: '2022-01-14'
        },
        {
          img: `${this.$url}Journalism/style5.jpg`,
          text: '不剃发植发重塑行业天花板，碧莲盛当选植发行业领军企业',
          date: '2022-01-09'
        },
        {
          img: `${this.$url}Journalism/style6.jpg`,
          text: '碧莲盛董事长尤丽娜荣膺中国经济十大杰出女性',
          date: '2021-12-28'
        }
      ],
      addDynamicsList: [
        {
          img: `${this.$url}Journalism/Journalism7.jpg`,
          text: '碧莲盛携手鸿基金启动“爱的翅膀·助力成才计划”',
          date: '2021-03-20'
        },
        {
          img: `${this.$url}Journalism/Journalism8.jpg`,
          text: '专家热议“新消费”：多措并举促进新业态新模式健康发展',
          date: '2021-03-10'
        },
        {
          img: `${this.$url}Journalism/Journalism9.jpg`,
          text: '潮流大秀视听盛宴隆重开幕 碧莲盛时尚盛典名流汇聚尽显奢华',
          date: '2020-11-26'
        },
        {
          img: `${this.$url}Journalism/Journalism10.jpg`,
          text: '斩获两项大奖，碧莲盛成为第七届中国行业影响力品牌峰会焦点',
          date: '2020-08-30'
        },
        {
          img: `${this.$url}Journalism/Journalism11.jpg`,
          text: '创新 匠心 仁心，植发行业赋能美好生活',
          date: '2020-08-18'
        },
        {
          img: `${this.$url}Journalism/Journalism12.jpg`,
          text: '2020国际质造节圆满落幕，碧莲盛植发荣获两大奖项',
          date: '2020-07-29'
        }
      ],
      selectBab: 1
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.selectBab = this.$route.params.id + 1
    }
  },
  methods: {
    setItem (a) {
      this.selectBab = a
    },
    lookMore () {
      this.vidoList = [...this.vidoList, ...this.addVidoList]

      if (this.vidoList.length === 12) {
        this.isNoAdd = false
      }
    },
    showClick (index) {
      this.show = true
      this.isSelect = index
    },
    lookMoreT () {
      this.DynamicsList = [...this.DynamicsList, ...this.addDynamicsList]
      if (this.DynamicsList.length === 12) {
        this.isNoAddT = false
      }
    }
  }
}
</script>

<style lang="scss">
.journalism {
  background: #f9fafb;
}
.Dynamics {
  .Dynamics-line {
    width: 502px;
    border-bottom: solid 1px #d4d3d3;
  }
  .Dynamics-item {
    display: flex;
    padding: 32px 0;
    border-bottom: solid 1px #d4d3d3;

    .Dynamics-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
      .Dynamics-date {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #9f9f9f;
      }
      .Dynamics-title {
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: 0px;
        color: #353535;
      }
    }
    .Dynamics-right {
      width: 189px;
      height: 123px;
    }
  }
}
.lookMore {
  width: 160px;
  height: 35px;
  border-radius: 15px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #707070;
  line-height: 35px;
  border: solid 1px #707070;
  margin: 40px auto 0;
  display: flex;
  justify-content: center;
}
.out-top {
  width: 15px;
  height: 15px;
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
  transform: translate(0, 0) rotate(45deg);
  margin-left: 7px;
  margin-top: 5px;
}
.VideoReport {
  position: relative;
  margin-bottom: 20px;

  .VideoReport-img {
    width: 500px;
    height: 282px;
    vertical-align: middle;
  }
  .VideoReport-item {
    width: 500px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    background-color: #ffffff;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #363636;
  }
  .VideoReport-bf {
    position: absolute;
    width: 93px;
    height: 93px;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
