<template>
  <div class="mark">
    <div class="alert">
      <div class="alert-top">
        <div class="journalismProp">
          <div class="error" @click="$emit('setShow')"></div>

          <video
            autoplay
            style="object-fit: cover; outline: none"
            width="100%"
            :src="selectSrc"
            :poster="poster"
            ref="videoPlay"
            controls
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSelect: {
      type: Number,
      default: 0
    }
  },
  created () {
    this.selectSrc = this.imgList[this.isSelect].video
    this.poster = this.imgList[this.isSelect].img
  },
  data () {
    return {
      poster: '',
      selectSrc: '',
      imgList: [
        {
          img: `${this.$url}/Journalism/Journalism1.jpg`,
          video: `${this.$url}/Journalism/vido/vido1.mp4`,
          text: 'CCTV 《创新之路》',
          inde: 0
        },
        {
          img: `${this.$url}/Journalism/Journalism2.jpg`,
          text: 'CCTV13 《新闻直播间》 ',
          video: `${this.$url}/Journalism/vido/vido2.mp4`,
          inde: 1
        },
        {
          img: `${this.$url}/Journalism/Journalism3.jpg`,
          video: `${this.$url}/Journalism/vido/vido3.mp4`,
          text: '人民网《人民会客厅·两会时刻》 ',
          inde: 2
        },
        {
          img: `${this.$url}/Journalism/Journalism4.jpg`,
          video: `${this.$url}/Journalism/vido/20160829%E5%B0%8F%E8%94%A1-%E5%A4%AE%E8%A7%86%E4%B8%80%E5%A5%97%E7%94%9F%E6%B4%BB%E5%9C%88-%E5%B0%B9%E6%A2%93%E8%B4%BB%E5%87%BA%E5%B8%AD%281%29%281%29.mp4`,
          text: 'CCTV1 《生活圈》 ',
          inde: 3
        },
        {
          img: `${this.$url}/Journalism/Journalism5.jpg`,
          video: `${this.$url}/Journalism/vido/vido5.mp4`,
          text: 'BTV 《品质生活》',
          inde: 4
        },
        {
          img: `${this.$url}/Journalism/Journalism6.jpg`,
          video: `${this.$url}/Journalism/vido/vido6.mp4`,
          text: 'CCTV 《连线中国》 ',
          inde: 5
        },
        {
          img: `${this.$url}Journalism/style7.jpg`,
          video: `${this.$url}/Journalism/vido/vido7.mp4`,
          text: 'CCTV《焦点关注》',
          inde: 6
        },
        {
          img: `${this.$url}Journalism/style8.jpg`,
          video: `${this.$url}/Journalism/vido/vido8.mp4`,
          text: '钛媒体《华楠直播间》',
          inde: 7
        },
        {
          img: `${this.$url}Journalism/style9.jpg`,
          video: `${this.$url}/Journalism/vido/vido9.mp4`,
          text: '山西电视台《黄河新闻》',
          inde: 8
        },
        {
          img: `${this.$url}Journalism/style10.jpg`,
          video: `${this.$url}/Journalism/vido/vido10.mp4`,
          text: '广东电视台《创新广东》',
          inde: 9
        },
        {
          img: `${this.$url}Journalism/style11.jpg`,
          video: `${this.$url}/Journalism/vido/vido11.mp4`,
          text: '甘肃电视台《今日文化君',
          inde: 10
        },
        {
          img: `${this.$url}Journalism/style12.jpg`,
          video: `${this.$url}/Journalism/vido/vido12.mp4`,
          text: '南昌电视台《新闻说报》',
          inde: 11
        }
      ]
    }
  }
}
</script>

  <style scoped lang="scss">
.mark {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  .alert {
  }
  .alert-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 163px;
      height: 35px;
    }
    .error {
      width: 50px;
      height: 50px;
      float: right;
      position: relative;
      cursor: pointer;
    }

    .error::before,
    .error::after {
      content: "";
      position: absolute;
      height: 30px;
      width: 2px;
      top: 10px;
      left: 26px;

      background: #ffffff;
    }

    .error::before {
      transform: rotate(45deg);
    }

    .error::after {
      transform: rotate(-45deg);
    }
  }
}
</style>
